import React from 'react'
import { useTranslation } from 'react-i18next'

import TeamIcon from '../assets/svg/team.svg'

const Team = () => {
    const { t } = useTranslation('team')

    return (
        <div className="teamPage" id="team">
            <h2 className="pageTitle">{t('header')}</h2>
            <hr />
            <div className="teamContents">
                <div>
                    <p>{t('paragraph1')}</p>
                    <p>{t('paragraph2')}</p>
                </div>
                <TeamIcon />
            </div>
        </div>
    )
}

export default Team
